import {HttpClient, HttpContext} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {API_CONSTANTS} from '../../constant/apiConstants';

export interface IOrganization {
  orgName: string;
  orgId: string;
}

export interface IDefaultOrgDetails {
  userPoolId: string;
  defaultAppClientId: string;
  orgName: string;
}

@Injectable({
  providedIn: 'root',
})
export class DomainOrganizationService {
  organizations: BehaviorSubject<IOrganization[] | null> = new BehaviorSubject<
    IOrganization[] | null
  >(null);
  userName: string | undefined;

  constructor(private httpClient: HttpClient) {}

  getOrganizationsUsingLoginName(loginName: string): Observable<IOrganization[]> {
    return this.httpClient.get<IOrganization[]>(
      environment.apiUrl + API_CONSTANTS.LOGIN_ORG_MAPPING.replace(':loginName', loginName),
    );
  }

  getDefaultOrgDetails(organizationID: string): Observable<IDefaultOrgDetails> {
    return this.httpClient.get<IDefaultOrgDetails>(
      environment.apiUrl + API_CONSTANTS.ORG_DETAILS.replace(':orgId', organizationID),
    );
  }
}
