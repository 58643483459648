import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {API_CONSTANTS} from '../../constant/apiConstants';
import {WindowService} from '../services/window.service';
import {CognitoService} from './cognito.service';

export interface IClientInfo {
  id: string;
  name: string;
  callbackUrls: string[];
  logoutUrls: string[];
  userPoolId: string;
  defaultRedirectUri?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class AppAuthorizationService {
  constructor(
    private httpClient: HttpClient,
    private _cognitoService: CognitoService,
    private _window: WindowService,
  ) {}

  public async routeToClientApp(
    orgId: string,
    clientId: string,
    isDefaultClientId: boolean = false,
  ): Promise<void> {
    const token = await this._cognitoService.getAccessToken();
    this.httpClient
      .get<IClientInfo>(
        environment.apiUrl +
          API_CONSTANTS.APP_DETAIL.replace(':orgId', orgId).replace(':clientId', clientId),
        {
          observe: 'response',
          responseType: 'json',
          headers: {'Authorization': `Bearer ${token}`},
        },
      )
      .subscribe({
        next: (httpResponse) => {
          if (httpResponse.ok) {
            const {
              defaultRedirectUri,
              callbackUrls: [callbackUrl],
            } = httpResponse.body as IClientInfo;
            if (defaultRedirectUri || callbackUrl) {
              this._window.route(defaultRedirectUri || callbackUrl);
            } else if (!isDefaultClientId) {
              const {defaultAppClientId} = this._cognitoService.getOrgDetails();
              this.routeToClientApp(orgId, defaultAppClientId, true);
            }
          }
        },
        error: (error) => {
          if (!isDefaultClientId) {
            const {defaultAppClientId} = this._cognitoService.getOrgDetails();
            this.routeToClientApp(orgId, defaultAppClientId, true);
          }
        },
      });
  }
}
